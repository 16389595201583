import React from 'react';
import { TextField, ReferenceInput, ReferenceField, AutocompleteInput} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const roles = [
    { name: "ROLE_MANAGER", cname: "manager", value: ["ROLE_MANAGER"] },
    { name: "ROLE_WORKER", cname: 'trabajador', value: ["ROLE_WORKER"] },
];

export const AccessPointsList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="address" />
            <FieldGuesser source="ip" />
            <FieldGuesser source="clients_ip_range" />
            <ReferenceField label="Node" source="node" reference="nodes">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="partners" />
        </ListGuesser>
    </div>

);

export const AccessPointCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="ip" />
        <InputGuesser source="clients_ip_range" />
        <ReferenceInput
            source="node"
            reference="nodes"
            label="Node"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </CreateGuesser>
);

export const AccessPointEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="ip" />
        <InputGuesser source="clients_ip_range" />
        <ReferenceInput
            source="node"
            reference="nodes"
            label="Node"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
