import React from 'react';
import { TextField, ReferenceInput, AutocompleteInput, ReferenceField, BooleanField, BooleanInput, Filter} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const roles = [
    { name: "ROLE_MANAGER", cname: "manager", value: ["ROLE_MANAGER"] },
    { name: "ROLE_WORKER", cname: 'trabajador', value: ["ROLE_WORKER"] },
];

export const PartnersFilter = (props) => (
    <Filter {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="last_name" />
        <InputGuesser source="dni" />
        <InputGuesser source="supply_address" />
        <InputGuesser source="vpn_name" />
        <InputGuesser source="ip" />
        <InputGuesser source="iban" />
        <InputGuesser source="phone" />
        <InputGuesser source="email" />
        <BooleanInput source="enabled" />
        <BooleanInput source="is_annual" />
        <ReferenceInput label="Access Point Name" source="accessPoint.id" reference="access_points" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Access Point ip" source="accessPoint.ip" reference="access_points" allowEmpty>
            <AutocompleteInput optionText="ip" />
        </ReferenceInput>
        <ReferenceInput label="Node Name" source="accessPoint.node.name" reference="nodes" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const PartnersList = props => (
    <div>
        <ListGuesser {...props} filters={<PartnersFilter/>}>
            <FieldGuesser source="name" />
            <FieldGuesser source="last_name" />
            <FieldGuesser source="supply_address" />
            <FieldGuesser source="phone" />
            <FieldGuesser source="vpn_name" />
            <FieldGuesser source="ip" />
            <BooleanField source="is_annual"/>
            <BooleanField source="enabled"/>
            <ReferenceField label="AccessPoint" source="accessPoint" reference="access_points">
                <TextField source="name"/>
            </ReferenceField>
        </ListGuesser>
    </div>

);

export const PartnersCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="last_name" />
        <InputGuesser source="dni" />
        <InputGuesser source="supply_address" />
        <InputGuesser source="phone" />
        <InputGuesser source="email" />
        <InputGuesser source="birthday" />
        <InputGuesser source="vpn_name" />
        <InputGuesser source="vpn_password" />
        <InputGuesser source="ip" />
        <InputGuesser source="iban" />
        <BooleanInput source="is_annual" />
        <BooleanInput source="enabled" />
        <ReferenceInput
            source="accessPoint"
            reference="access_points"
            label="AccessPoint"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

export const PartnerEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="last_name" />
        <InputGuesser source="dni" />
        <InputGuesser source="supply_address" />
        <InputGuesser source="phone" />
        <InputGuesser source="email" />
        <InputGuesser source="birthday" />
        <InputGuesser source="vpn_name" />
        <InputGuesser source="vpn_password" />
        <InputGuesser source="ip" />
        <InputGuesser source="iban" />
        <BooleanInput source="is_annual" />
        <BooleanInput source="enabled" />
        <ReferenceInput
            source="accessPoint"
            reference="access_points"
            label="AccessPoint"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
