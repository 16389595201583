import React from 'react';
import { HydraAdmin } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {
    dataProvider as baseDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser  ,
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
} from '@api-platform/admin';
import authProvider from './authProvider';
import { Redirect } from 'react-router-dom';

import { Admin, Resource } from 'react-admin';

import Dashboard from './Dashboard';

import {UsersList, UsersCreate, UserEdit } from './Users';
import {PartnersCreate, PartnersList, PartnerEdit} from "./Partners";
import {NodeEdit, NodesCreate, NodesList} from "./Nodes";
import {ContractsList, ContractEdit, ContractsCreate} from "./Contracts";
import {AccessPointCreate, AccessPointEdit, AccessPointsList} from "./AccessPoints";

import {customTheme} from "./CustomTheme";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={`/login`}/>,
                            },
                        }],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseDataProvider(entrypoint, fetchHydra, apiDocumentationParser,true);


export default props => (
    <HydraAdmin
        apiDocumentationParser={ apiDocumentationParser }
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        dashboard={Dashboard}
        theme={customTheme}
    >
        <ResourceGuesser name="users" create={UsersCreate} list={UsersList} edit={UserEdit}/>
        <ResourceGuesser name="nodes" create={NodesCreate} list={NodesList} edit={NodeEdit} />
        <ResourceGuesser name="access_points" create={AccessPointCreate} list={AccessPointsList} edit={AccessPointEdit} />
        <ResourceGuesser name="partners" create={PartnersCreate} list={PartnersList} edit={PartnerEdit}/>
        <ResourceGuesser name="contracts" create={ContractsCreate} list={ContractsList} edit={ContractEdit} />

    </HydraAdmin>

);
