import React from 'react';
import {ReferenceField, TextField, ReferenceInput, AutocompleteInput, Filter, DateInput} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const status = [
    { name: "pending", cname: "pending", value: "pending" },
    { name: "paid", cname: 'paid', value: "paid" },
];

export const ContractsFilter = (props) => (
    <Filter {...props} >
        <InputGuesser source="status" />
        <DateInput source="start" />
        <InputGuesser source="finish" />
        <ReferenceInput label="Partner name" source="partner.name" reference="partners" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Partner last name" source="partner.last_name" reference="partners" allowEmpty>
            <AutocompleteInput optionText="last_name" />
        </ReferenceInput>
        <ReferenceInput label="Partner vpn name" source="partner.vpn_name" reference="partners" allowEmpty>
            <AutocompleteInput optionText="last_name" />
        </ReferenceInput>
        <ReferenceInput label="AP name" source="partner.accessPoint.name" reference="access_points" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Node name" source="partner.accessPoint.node.name" reference="nodes" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const ContractsList = props => (
    <div>
        <ListGuesser {...props} filters={<ContractsFilter/>}>
            <FieldGuesser source="start" />
            <FieldGuesser source="finish" />
            <FieldGuesser source="amount" />
            <FieldGuesser source="status" />
            <ReferenceField label="Partner" source="partner" reference="partners">
                <TextField source="name"/>
            </ReferenceField>
        </ListGuesser>
    </div>

);

export const ContractsCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="start" />
        <InputGuesser source="finish" />
        <InputGuesser source="amount" />
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partner"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

export const ContractEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="start" />
        <InputGuesser source="finish" />
        <InputGuesser source="amount" />
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="partner"
            reference="partners"
            label="Partner"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
