import React from 'react';
import { TextField, ReferenceInput, ReferenceField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const roles = [
    { name: "ROLE_MANAGER", cname: "manager", value: ["ROLE_MANAGER"] },
    { name: "ROLE_WORKER", cname: 'trabajador', value: ["ROLE_WORKER"] },
];

export const NodesList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="address" />
            <FieldGuesser source="ip" />
            <FieldGuesser source="accessPoints" />
        </ListGuesser>
    </div>

);

export const NodesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="ip" />
    </CreateGuesser>
);

export const NodeEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="ip" />

    </EditGuesser>
);
